<template>
    <div>
        <cta-banner :title="$route.name"/>
        <cta-description class="md:pt-28" :descripcion="header" :patrocinar="true"/>
        <cta-calendar :events="events"
                      :fecha_inicial="fecha_inicial"
                      :cell_height="cell_height">
        </cta-calendar>
    </div>
</template>

<script>
    import API from "@/api";
    import CtaBanner from '../../components/common/CtaBanner.vue';
    import CtaDescription from '../../components/CtaDescription.vue';
    import CtaCalendar from "../../components/CtaCalendar";
    import {mapState} from "vuex";

    export default {
        name: "General",
        components: {
            CtaCalendar,
            CtaBanner,
            CtaDescription
        },
        data: () => ({
            events: [],
            fecha_inicial: "2021-09-01",
            cell_height: 220,
            header: {}
        }),
        created() {
            this.getInfo();
            this.getProgramacion();
        },
        computed: {
            ...mapState(["idEventoActual"]),
        },
        methods: {
            async getInfo() {
                const params = {
                    "evento_fk": this.idEventoActual,
                    format: "json"
                };
                const {data} = await API.getInfo(params);
                let items = data.results;
                for (let i in items) {
                    this.header = (items[i].codigo === 'PO01') ? items[i] : this.header;
                }
            },
            async getProgramacion() {
                const {data} = await API.getProgramacion({evento_fk: this.idEventoActual});
                let eventos = data.results;

                for (let i in eventos) {
                  let evento = eventos[i];

                  let fechai = evento.fecha_inicio.split('T');
                  let fecha_inicio =  fechai[0] + ' ' + fechai[1].split('-')[0];

                  let fechaf = evento.fecha_fin.split('T');
                  let fecha_fin =  fechaf[0] + ' ' + fechaf[1].split('-')[0];

                  if (evento.tipo === 'lunch' || evento.tipo === 'clausura'){
                    this.events.push({
                      start: fecha_inicio,
                      end: fecha_fin,
                      title: evento.nombre,
                      class: evento.tipo,
                      background: true
                    });
                  } else {
                    this.events.push({
                      start: fecha_inicio,
                      end: fecha_fin,
                      title: evento.nombre,
                      content: evento.descripcion,
                      class: evento.tipo
                    });
                  }
                }
            }
        },
    }
</script>

<style type="text/css">

</style>
